import React, { useEffect, useLayoutEffect, useState } from 'react';
import './App.css';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import { UntappdMenuItem, UntappdMenuContainer } from './untappd-types';
import MobileDisplayItemV3 from './MobileDisplayItemV3';
import Typography from '@mui/material/Typography';
import { Routes, Route, useParams } from 'react-router-dom';
import MobileCocktailItem from './MobileCocktailItem';
import { CocktailMenuItem } from './menu-types';

//Read for more about CSS Grid layouts: https://mui.com/system/grid/

const classic1: CocktailMenuItem = { id: 1, name: 'Martini', style: 'Draft', price: 12, description: 'Choice of Ology Vodka or Gin, Dry Vermouth, Shaken or Stirred' }
const classic2: CocktailMenuItem = { id: 2, name: 'Manhattan', style: 'Draft', price: 12, description: 'Ology Small Batch Rye Whiskey, Sweet Vermouth, Bitters' }
const classic3: CocktailMenuItem = { id: 3, name: 'Espresso Martini', style: 'Draft', price: 13, description: 'Ology Vodka, Fresh Espresso G.O.A.T. Creme Liqueur, Turbinado Simple' }
const classic4: CocktailMenuItem = { id: 4, name: 'Old Fashioned', style: 'Draft', price: 11, description: 'Ology Small Batch Bourbon, Turbincado Simple, Bitters' }
const classic5: CocktailMenuItem = { id: 5, name: 'Caipirinha', style: 'Draft', price: 12, description: 'Grilled Pineapple Agave, Clove, Sarsaparilla, Vanilla, Hibiscus, Soda' }
const classic6: CocktailMenuItem = { id: 6, name: 'Whiskey Sour', style: 'Draft', price: 12, description: 'Oleo Saccharum, Juniper, Rosemary, Sage, Citric and Malic Acid Solution' }
const classic7: CocktailMenuItem = { id: 7, name: 'Gimlet', style: 'Signature', price: 12, description: 'Butterfly tea infused Ology Vodka, Lemon Juice, Champagne, Simple' }
const classic8: CocktailMenuItem = { id: 8, name: 'Irish Coffee', style: 'Signature', price: 10, description: 'Ology London Dry Gin, Lemon Juice, Hibiscus-Lavender Infused Honey' }
const classic9: CocktailMenuItem = { id: 9, name: 'Midwest Martini', style: 'Signature', price: 6, description: 'Ology Small Batch Bourbon, Lemon Juice, Ginger, Bitters' }

const signature1: CocktailMenuItem = { id: 20, name: 'Bicentennial Cocktail', style: 'Signature', price: 11, description: 'Ology Single Barrel Golden Rum, Lime Juice, Orange Oleo Saccharum, Brown Sugar Spice, Bitters' }
const signature2: CocktailMenuItem = { id: 21, name: 'Sweet Summer Child', style: 'Signature', price: 10, description: 'Ology New World Gin, Rosemary, Sage, Juniper, Lemon-Lime Oleo Saccharum' }
const signature3: CocktailMenuItem = { id: 22, name: 'The Busy Beezzzzz', style: 'Signature', price: 12, description: 'Ology London Dry Gin, Lemon Juice, Hibiscus-Lavender Infused Honey' }
const signature4: CocktailMenuItem = { id: 23, name: 'Sushi Tango', style: 'Signature', price: 11, description: 'Ology New World Gin, Rosemary-Nori Simple, Burlesque Bitters' }
const signature5: CocktailMenuItem = { id: 24, name: 'Chat G&T', style: 'Signature', price: 9, description: 'Ology London Dry Gin, Ology Coffee Liqueur, Tonic Water' }
const signature6: CocktailMenuItem = { id: 25, name: 'Orthodox Punch', style: 'Signature', price: 10, description: 'Mixed Berry-infused Ology New World Gin, Brovo Pretty Sweet Vermouth, Turbinado Simple' }
const signature7: CocktailMenuItem = { id: 26, name: 'Sage Francis', style: 'Signature', price: 12, description: 'Ology New World Gin, Rosemary, Sage, Juniper, Lemon-Lime Oleo Saccharum' }
const signature8: CocktailMenuItem = { id: 27, name: 'Florida Man', style: 'Signature', price: 10, description: 'Ology New World Gin, Fresh-squeezed Orange Juice, Orange Oleo Saccharum, Cola Syrup, Burlesque Bitters' }
const signature9: CocktailMenuItem = { id: 28, name: 'Indigo Wings', style: 'Signature', price: 12, description: 'Butterfly tea-infused Ology Vodka, Lemon Juice, Champagne, Simple' }
const signature10: CocktailMenuItem = { id: 29, name: 'Where\'s the Apple?!', style: 'Signature', price: 12, description: 'House-made Almond Liqueur, Cinnamon-infused Ology Small Batch Rye, Lemon Juice, Turbinado Simple, Egg Whites' }
const signature11: CocktailMenuItem = { id: 30, name: 'Infused Mojito', style: 'Signature', price: 10, description: 'Fruit-infused Ology White Rum, Pineapple Simple, Lime Juice, Soda Water,(Rotating Flavors)' }
const signature12: CocktailMenuItem = { id: 31, name: 'Riki Julius', style: 'Signature', price: 13, description: 'Ology Golden Rum, Lime Juice, Orange Oleo Saccharum, Brown Sugar Spice Simple, Bitters' }
const signature13: CocktailMenuItem = { id: 32, name: 'Jangle Bird', style: 'Signature', price: 13, description: 'Ology Golden Rum, Cappelletti Vino Apertivo, Pineapple Juice, Lime Juice, Passionfruit Simple' }
const signature14: CocktailMenuItem = { id: 33, name: 'Root Beer Old Fashioned', style: 'Signature', price: 13, description: 'Ology Small Batch Rye, Homemade Root Beer Syrup, Black Walnut Bittlers' }
const signature15: CocktailMenuItem = { id: 34, name: 'Northside Sour', style: 'Signature', price: 11, description: 'Ology Small Batch Bourbon, Lemon Juice, Turbinado Simple, Cabernet Sauvignon Float' }

const birthdayShot: CocktailMenuItem = { id: 40, name: 'Birthday Shot', style: 'Shot', price: 5, description: 'A shot of Ology Birthday Cake Crème Liqueur' }

const mocktail1: CocktailMenuItem = { id: 51, name: 'Earth Tones', style: 'Cocktail', price: 7, description: 'Oleo Saccharum, Juiper, Rosemary, Sage, Citric and Malic Acid' }
const mocktail2: CocktailMenuItem = { id: 52, name: 'Lavender Lemonade', style: 'Cocktail', price: 5, description: 'Lavender Simple, Lemon Juice, Soda Water' }


const MobileCocktailMenu = () => {
  return (
    <main style={{ backgroundColor: 'black', overflowY: 'hidden', paddingTop: '0.5em', paddingLeft: '0em' }}>
      {/* Hero unit */}
        <Container sx={{ py: 0.0, px: 0.25, paddingTop: '0px' }} maxWidth={false}>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0em' }} variant="h3" component="h3">Classic Cocktails</Typography>
            <MobileCocktailItem key={classic1.id} menuItem={classic1} ></MobileCocktailItem>
            <MobileCocktailItem key={classic2.id} menuItem={classic2} ></MobileCocktailItem>
            <MobileCocktailItem key={classic3.id} menuItem={classic3} ></MobileCocktailItem>
            <MobileCocktailItem key={classic4.id} menuItem={classic4} ></MobileCocktailItem>
            <MobileCocktailItem key={classic5.id} menuItem={classic5} ></MobileCocktailItem>
            <MobileCocktailItem key={classic6.id} menuItem={classic6} ></MobileCocktailItem>
            <MobileCocktailItem key={classic7.id} menuItem={classic7} ></MobileCocktailItem>
            <MobileCocktailItem key={classic8.id} menuItem={classic8} ></MobileCocktailItem>
            <MobileCocktailItem key={classic9.id} menuItem={classic9} ></MobileCocktailItem>
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0.5em' }} variant="h3" component="h3">Signature Cocktails</Typography>
          <MobileCocktailItem key={signature1.id} menuItem={signature1} ></MobileCocktailItem>
            <MobileCocktailItem key={signature2.id} menuItem={signature2} ></MobileCocktailItem>
            <MobileCocktailItem key={signature3.id} menuItem={signature3} ></MobileCocktailItem>
            <MobileCocktailItem key={signature4.id} menuItem={signature4} ></MobileCocktailItem>
            <MobileCocktailItem key={signature5.id} menuItem={signature5} ></MobileCocktailItem>
            <MobileCocktailItem key={signature6.id} menuItem={signature6} ></MobileCocktailItem>
            <MobileCocktailItem key={signature7.id} menuItem={signature7} ></MobileCocktailItem>
            <MobileCocktailItem key={signature8.id} menuItem={signature8} ></MobileCocktailItem>
            <MobileCocktailItem key={signature9.id} menuItem={signature9} ></MobileCocktailItem>
            <MobileCocktailItem key={signature10.id} menuItem={signature10} ></MobileCocktailItem>
            <MobileCocktailItem key={signature11.id} menuItem={signature11} ></MobileCocktailItem>
            <MobileCocktailItem key={signature12.id} menuItem={signature12} ></MobileCocktailItem>
            <MobileCocktailItem key={signature13.id} menuItem={signature13} ></MobileCocktailItem>
            <MobileCocktailItem key={signature14.id} menuItem={signature14} ></MobileCocktailItem>
            <MobileCocktailItem key={signature15.id} menuItem={signature15} ></MobileCocktailItem>
          </Box>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0.5em' }} variant="h3" component="h3">Birthday</Typography>
            <MobileCocktailItem key={birthdayShot.id} menuItem={birthdayShot} ></MobileCocktailItem>
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0.5em' }} variant="h3" component="h3">Zero Proof Mocktails</Typography>
            <MobileCocktailItem key={mocktail1.id} menuItem={mocktail1} ></MobileCocktailItem>
            <MobileCocktailItem key={mocktail2.id} menuItem={mocktail2} ></MobileCocktailItem>
          </Box>
        </Container>
    </main>
  );
}

export default MobileCocktailMenu;
