import React, { useEffect, useLayoutEffect, useState } from 'react';
import './App.css';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import { UntappdMenuItem, UntappdMenuContainer } from './untappd-types';
import MobileDisplayItemV3 from './MobileDisplayItemV3';
import Typography from '@mui/material/Typography';
import { Routes, Route, useParams } from 'react-router-dom';
import MobileFoodItem from './MobileFoodItem';
import { FoodMenuItem } from './menu-types';

//Read for more about CSS Grid layouts: https://mui.com/system/grid/

const pretzel: FoodMenuItem = { id: 1, name: 'German Style Pretzel', style: 'Shareable', price: 12, description: 'Served with a side of Ology Lager Beer Cheese and Creole Mustard.' }
const buffalo: FoodMenuItem = { id: 2, name: 'Buffalo Chicken Dip', style: 'Shareable', price: 8, description: 'Chopped and marinated chicken breast, blue cheese crumbles, celery, and buffalo sauce. Served with tortilla chips.' }
const peanuts: FoodMenuItem = { id: 3, name: 'Cajon Boilded Peanuts', style: 'Shareable', price: 5, description: 'Ology Lager Boiled Peanuts with Cajun seasoning.' }
const empanadas: FoodMenuItem = { id: 4, name: 'Dos Empanadas', style: 'Shareable', price: 7, description: 'Two oversized baked empanadas with a crispy pastry dough. Includes one chicken and one beef. Served with an avocado lime empanada sauce.' }

const smashBurgerFlatbread: FoodMenuItem = { id: 16, name: 'Ology Smash Burger Flatbread', style: 'Flatbreads', price: 13, description: 'Seasoned ground beef, sauteed onions, dill pickles, cheddar cheese, topped with Ology Smash Sauce.' }
const openOnSundaysFlatbread: FoodMenuItem = { id: 17, name: 'Open on Sunday Flatbread', style: 'Flatbreads', price: 13, description: 'Aioli base, breaded chicken, cheddar cheese, dill pickle slices, finished with Flic Cil A sauce.' }
const capreseFlatbread: FoodMenuItem = { id: 18, name: 'FL/Georgia Caprese Flatbread', style: 'Flatbreads', price: 10, description: 'Roasted GA pecan and FL arugula pesto base, marinated roasted plum tomatoes, fresh mozzarella, fresh basil, finished with a balsamic reduction.' }
const buffaloFlatbread: FoodMenuItem = { id: 19, name: 'Roamiong Buffalo Flatbread', style: 'Flatbreads', price: 11, description: 'Ranch base, breaded chicken, blue cheese crumbles, cheddar cheese blend, diced Vidalia onions, and finished with a buffalo drizzle.' }
const marketVegFlatbread: FoodMenuItem = { id: 18, name: 'Market Veg Flatbrtead', style: 'Flatbreads', price: 10, description: 'Aioli base, seasoned eggplant,Vidalia onions, sweet peppers, yellow squash, zucchini, Gruyere, Fontina and Gouda cheese blend, with aioli drizzle.' }
const piccataFlatbread: FoodMenuItem = { id: 19, name: 'Chicken Piccata Flatbread', style: 'Flatbreads', price: 11, description: 'Lemon-garlic marinated chicken, Gruyere, Fontina and Gouda cheese blend, capers, and piccata aioli.' }

const doubleBakedPrtzels: FoodMenuItem = { id: 21, name: 'Double-Baked Mini Pretzels', style: 'Sides', price: 2.5, description: '' }
const chips: FoodMenuItem = { id: 22, name: 'Deep River Kettle Chips', style: 'Sides', price: 3, description: '' }
const sideSalad: FoodMenuItem = { id: 23, name: 'Side Salad', style: 'Sides', price: 3, description: '' }

const eggSausageEmpanadas: FoodMenuItem = { id: 31, name: 'Egg & Sausage Empanadas', style: 'Breakfast/Brunch', price: 6, description: 'Two oversized baked empanadas with a crispy pastry dough filled with egg and sausage served with an avocado lime empanada sauce.' }
const dirtySouthwestBurrito: FoodMenuItem = { id: 31, name: 'Dirty Southwest Burrito', style: 'Breakfast/Brunch', price: 7, description: 'Burrito with bacon, breakfast sausage, scrambled eggs, hatch chile, sautéed peppers and onions, and cheddar cheese. Served with a side of chipotle lime crema.' }
const chorizoEggBurrito: FoodMenuItem = { id: 31, name: 'Chorizo and Egg Burrito', style: 'Breakfast/Brunch', price: 7, description: 'Scrambled eggs, breakfast potatos, Spanish chorizo queso, cheddar cheese, Chulula hot sauce, served in a pressed flour tortilla.' }
const veggieBurrito: FoodMenuItem = { id: 32, name: 'Vegetable Egg Burrito', style: 'Breakfast/Brunch', price: 7, description: 'Vegetable egg burrito with scrambled eggs, seasoned eggplant, onions, sweet peppers, squash, and zucchini.' }
const smokedGoudaGrits: FoodMenuItem = { id: 33, name: 'Smoked Gouda Grits', style: 'Breakfast/Brunch', price: 7, description: 'Creamy grits with smoked Gouda, scrambled egg bite, cheddar cheese, and Hollandause sauce.' }
const chickenWaffleSandwich: FoodMenuItem = { id: 34, name: 'Chicken Waffle Sandwich', style: 'Breakfast/Brunch', price: 12, description: 'Crispy chicken breast on a fluffy Belgian waffle bun, accompanied by hot maple syrup butter.' }
const frenchToastBreadPudding: FoodMenuItem = { id: 35, name: 'French Toast Bread Pudding', style: 'Breakfast/Brunch', price: 8, description: 'Warm cinnamon toast bread pudding with mixed berry creme anglaise.' }
const southernChickenSaladBagel: FoodMenuItem = { id: 34, name: 'Southern Chicken Salad Bagel', style: 'Breakfast/Brunch', price: 12, description: 'A savory blend of Southern chicken salad with raisins, celery, sweet onions, and honey served on an everything bagel. Complimented by Dijon mustard and salami.' }
const breakfastAuGratin: FoodMenuItem = { id: 35, name: 'Breakfast Au Gratin', style: 'Breakfast/Brunch', price: 8, description: 'Layered breakfast casserole with eggs, maple sausage, hash browns, cheddar cheese, topped with a Hollandaise drizzle.' }


const MobileFoodMenu = () => {
  return (
    <main style={{ backgroundColor: 'black', overflowY: 'hidden', paddingTop: '0.5em', paddingLeft: '0em' }}>
      {/* Hero unit */}
        <Container sx={{ py: 0.0, px: 0.25, paddingTop: '0px' }} maxWidth={false}>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0em' }} variant="h3" component="h3">Sharable Snacks</Typography>
            <MobileFoodItem key={pretzel.id} menuItem={pretzel} ></MobileFoodItem>
            <MobileFoodItem key={buffalo.id} menuItem={buffalo} ></MobileFoodItem>
            <MobileFoodItem key={peanuts.id} menuItem={peanuts} ></MobileFoodItem>
            <MobileFoodItem key={empanadas.id} menuItem={empanadas} ></MobileFoodItem>
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0em' }} variant="h3" component="h3">FoodLab Flatbreads</Typography>
            <MobileFoodItem key={smashBurgerFlatbread.id} menuItem={smashBurgerFlatbread} ></MobileFoodItem>
            <MobileFoodItem key={openOnSundaysFlatbread.id} menuItem={openOnSundaysFlatbread} ></MobileFoodItem>
            <MobileFoodItem key={capreseFlatbread.id} menuItem={capreseFlatbread} ></MobileFoodItem>
            <MobileFoodItem key={buffaloFlatbread.id} menuItem={buffaloFlatbread} ></MobileFoodItem>
            <MobileFoodItem key={marketVegFlatbread.id} menuItem={marketVegFlatbread} ></MobileFoodItem>
            <MobileFoodItem key={piccataFlatbread.id} menuItem={piccataFlatbread} ></MobileFoodItem>
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0em' }} variant="h3" component="h3">Sides</Typography>
            <MobileFoodItem key={doubleBakedPrtzels.id} menuItem={doubleBakedPrtzels} ></MobileFoodItem>
            <MobileFoodItem key={chips.id} menuItem={chips} ></MobileFoodItem>
            <MobileFoodItem key={sideSalad.id} menuItem={sideSalad} ></MobileFoodItem>
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0em' }} variant="h3" component="h3">Brunch <br/> 7am - 1pm</Typography>
            <MobileFoodItem key={eggSausageEmpanadas.id} menuItem={eggSausageEmpanadas} ></MobileFoodItem>
            <MobileFoodItem key={dirtySouthwestBurrito.id} menuItem={dirtySouthwestBurrito} ></MobileFoodItem>
            <MobileFoodItem key={chorizoEggBurrito.id} menuItem={chorizoEggBurrito} ></MobileFoodItem>
            <MobileFoodItem key={veggieBurrito.id} menuItem={veggieBurrito} ></MobileFoodItem>
            <MobileFoodItem key={smokedGoudaGrits.id} menuItem={smokedGoudaGrits} ></MobileFoodItem>
            <MobileFoodItem key={chickenWaffleSandwich.id} menuItem={chickenWaffleSandwich} ></MobileFoodItem>
            <MobileFoodItem key={frenchToastBreadPudding.id} menuItem={frenchToastBreadPudding} ></MobileFoodItem>
            <MobileFoodItem key={southernChickenSaladBagel.id} menuItem={southernChickenSaladBagel} ></MobileFoodItem>
            <MobileFoodItem key={breakfastAuGratin.id} menuItem={breakfastAuGratin} ></MobileFoodItem>
          </Box>
        </Container>
    </main>
  );
}

export default MobileFoodMenu;
